<template>
  <v-container id="dashboard" fluid tag="section">
    <v-row>
      <v-col cols="12" sm="8" offset-sm="2" md="6" offset-md="3" lg="4" offset-lg="4">
        <base-material-card color="primary" light max-width="100%" width="100%" class="px-5 py-3">
          <template v-slot:heading>
            <div class="text-center">
              <h1 class="display-2 font-weight-bold mb-2">
                {{ $t("Înregistrare") }}
              </h1>
            </div>
          </template>
          <v-card-text class="text-center">
            <v-form ref="formChange" @submit.prevent>
              <v-text-field
                v-model="username"
                color="primary"
                autofocus
                :label="$t('Nume prenume')"
                :rules="inputValidate"
                prepend-icon="mdi-account"
                @keydown.enter="$refs.email.focus()"
              />
              <v-text-field
                ref="email"
                v-model="email"
                color="primary"
                :label="$t('Email')"
                :rules="inputValidate"
                prepend-icon="mdi-email"
                @keydown.enter="$refs.password.focus()"
              />
              <v-text-field
                ref="password"
                v-model="password"
                color="primary"
                :label="$t('Password')"
                :rules="inputValidate"
                prepend-icon="mdi-lock-outline"
                :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show ? 'text' : 'password'"
                @click:append.prevent="show = !show"
                @keydown.enter="$refs.cpassword.focus()"
              />
              <v-text-field
                ref="cpassword"
                v-model="confirmPassword"
                color="primary"
                :label="$t('Confirmare parolă')"
                :rules="inputValidatePass"
                prepend-icon="mdi-lock-outline"
                :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show ? 'text' : 'password'"
                @click:append.prevent="show = !show"
                @keydown.enter="createAccount"
              />
            </v-form>
            <v-btn color="primary" @click="createAccount">
              {{ $t("Înregistrare") }}
            </v-btn>
          </v-card-text>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import axios from "../../../plugins/axios"
import md5 from "js-md5"
import { EventBus } from "../../../EventBus"
export default {
  name: "DashboardDashboard",
  components: {},
  data() {
    return {
      show: false,
      username: "",
      email: "",
      password: "",
      confirmPassword: ""
    }
  },
  computed: {
    inputValidate() {
      const rules = []
      rules.push(v => !!v || this.$t("Câmpul este obligatoriu"))
      return rules
    },
    inputValidatePass() {
      const rules = []
      rules.push(v => !!v || this.$t("Câmpul este obligatoriu"))
      rules.push(v => v == this.password || this.$t("Parolele nu sunt identice"))
      return rules
    },
    companyId() {
      return this.$store.getters.companyId
    },
    baseURL() {
      return axios.defaults.baseURL
    }
  },
  methods: {
    createAccount() {
      if (this.$refs.formChange.validate()) {
        const record = {
          company: this.companyId,
          username: this.username,
          fullname: this.username,
          email: this.email,
          password: md5(this.password),
          token: md5(this.username + this.email),
          settings: {
            app_type: "ebib",
            user_type: "web"
          }
        }
        axios.post("app_user", record).then(response => {
          this.$log("useradd ", response)
          if (response.status == 201) {
            this.username = ""
            this.email = ""
            this.password = ""
            this.confirmPassword = ""
            EventBus.$emit("show-alert", {
              message: this.$t("Contul a fost înregistrat. Vă rugăm confirmați înregistrarea pe adresa de email."),
              timeout: 7000
            })
            this.$router.push({ path: "/autentificare" })
          }
        })
      }
    }
  }
}
</script>
<style></style>
